import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import {
  nonSkipableFieldsTypes,
  nonCtaEnabledFields,
  ctaEnabledFields,
} from 'components/AVAILABLE_COMPONENTS';
import { DEFAULT_SELECT_VALUE, FIELD_NAMES } from 'consts';
import getVisibleQuestions from 'components/form-wizards/basic-form/getVisibleQuestions';
import { LogError } from './logging';

const {
  PRIMARY_PHONE,
  PRIMARY_PHONE_TYPE,
  SECONDARY_PHONE,
  SECONDARY_PHONE_TYPE,
  CITY,
  STATE,
} = FIELD_NAMES;

/**
 * @summary run this function over any form value to know if it should be allowed
 * @param {String|Object|Array} formValue
 * @returns Boolean - false if the provided value should not be allowed
 */
export function isInvalidValue(formValue) {
  if (typeof formValue === 'undefined' || formValue === null) {
    return true;
  }

  if (formValue === DEFAULT_SELECT_VALUE.value) {
    return true;
  }

  if (typeof formValue === 'boolean') {
    return false;
  }

  if (typeof formValue === 'string') {
    return !formValue.trim();
  }

  if (Array.isArray(formValue) && formValue.length === 0) {
    return true;
  }

  return false;
}

/**
 * @summary use this to get the value of a question in the form
 * @param {Object} formValues current formValues
 * @param {String} fieldName the name of the question you want
 * @return {Array<String>|String} answer(s) for the field requested
 */
export function getFieldValue(formValues, fieldName) {
  const fieldValue = formValues[fieldName];
  let answer = fieldValue;

  if (Array.isArray(fieldValue) && typeof fieldValue[0] === 'object') {
    // when value is an array of objects
    answer = fieldValue.map(({ value, guid }) => guid || value);
  } else if (Array.isArray(fieldValue)) {
    // when value is an array of primitives
    answer = fieldValue.map((value) => value);
  } else if (fieldValue && typeof fieldValue === 'object') {
    // when value is an object
    answer = fieldValue.guid || fieldValue.value;
  }

  return answer;
}

/**
 * @summary use this to generate the request object for backend
 */
export function formValuesToRequestArr(
  formValues,
  fieldNameMap,
  excludedPii,
  linkedSessionFormValues = {}
) {
  const replies = Object.keys(formValues).map((fieldName) => {
    const question = get(fieldNameMap, `[${fieldName}]`);
    let answer = getFieldValue(formValues, fieldName);

    // An ab test could have removed this from the page or we dont want to track PII
    if (!question || (question.isPii && excludedPii)) {
      return {};
    }

    // This is to handle the special Phone Number Widget
    if (fieldName === PRIMARY_PHONE) {
      answer = [
        answer,
        getFieldValue(formValues, PRIMARY_PHONE_TYPE),
        getFieldValue(formValues, SECONDARY_PHONE),
        getFieldValue(formValues, SECONDARY_PHONE_TYPE),
      ].filter((val) => val);
    } else if (formValues[fieldName]?.valueToSubmit) {
      // it's possible we don't want to send a field's value attribute
      answer = formValues[fieldName].valueToSubmit;
    }

    let status;

    if (get(linkedSessionFormValues, `[${fieldName}].isFromOtherSite`)) {
      status = 'shared';
    } else if (get(formValues, `[${fieldName}].shouldSkip`)) {
      status = 'single';
    }

    return {
      status,
      questionId: question.id,
      // All backend questionAnswer must be an array, they can not accept strings
      questionAnswer: Array.isArray(answer) ? answer : [answer],
    };
  });

  return replies.filter(
    ({ questionAnswer }) =>
      questionAnswer && !questionAnswer.includes(DEFAULT_SELECT_VALUE.value)
  );
}

/**
 * @summary given an array of question objects this will output a map keyed by their ids
 */
export function questionsToMap(questions) {
  return questions.reduce(
    (map, question) => ({
      ...map,
      [question.questionId]: {
        ...question,
        // If questions are grouped then just take the group, else rip out the options
        options: get(question, 'options[0].group')
          ? question.options
          : get(question, 'options[0].options', []),
      },
    }),
    {}
  );
}

/**
 * @summary given an array of question objects get the ones that have not been answered yet
 * @param {Array} questions - array of question objects
 * @param {Object} formValues current formValues
 */
export function getUnansweredQuestions(questions, formValues) {
  return questions.filter(({ name }) => {
    const fieldValue = getFieldValue(formValues, name);
    return (
      !fieldValue ||
      (Array.isArray(fieldValue) && !fieldValue.length) ||
      fieldValue === DEFAULT_SELECT_VALUE.value
    );
  });
}

/**
 * @summary given an array of question objects get the required ones that have not been answered yet
 * @param {Array} questions - array of question objects
 * @param {Object} formValues current formValues
 */
export function getUnansweredRequiredQuestions(questions, formValues) {
  return getUnansweredQuestions(questions, formValues).filter(
    ({ required }) => required
  );
}

/**
 * @summary we would want to skip the step if there are no question left to answer
 * @param {Array} questions - array of question objects
 * @param {Object} formValues current formValues
 * @param {string} currentBreakpoint current breakpoint
 * @param {Object} formState the whole form state object
 */
export function isStepSkipable(
  questions,
  formValues,
  currentBreakpoint,
  formState = {}
) {
  if (
    formState.formStatus?.completedSteps[formState.currentStepIndex] &&
    questions.length > 1
  ) {
    return false;
  }

  const unansweredQuestions = getUnansweredQuestions(questions, formValues);

  const questionTypes = questions.map(({ type, controlOverrides }) => {
    let currentType = type;

    if (currentBreakpoint && !isEmpty(controlOverrides)) {
      currentType = controlOverrides[currentBreakpoint] || currentType;
    }

    return currentType;
  });

  if (intersection(questionTypes, nonSkipableFieldsTypes).length) {
    return false;
  }

  // we assume this means the use clicked back, as the form will push the user to the next step if they answered all the questions
  if (!unansweredQuestions.length) {
    return true;
  }

  return false;
}

/**
 * @summary we only want to show the continue button if there is no input/multi select on the step or the user went back
 * @param {Array} questions - array of question objects
 */
export function shouldShowContinueButton(questions, currentBreakpoint) {
  const questionTypes = questions.map((question) => {
    let component = question.type;

    if (
      !isEmpty(question.controlOverrides) &&
      question.controlOverrides[currentBreakpoint]
    ) {
      component = question.controlOverrides[currentBreakpoint];
    }
    return component;
  });

  if (intersection(questionTypes, nonCtaEnabledFields).length) {
    return true;
  }

  if (intersection(questionTypes, ctaEnabledFields).length) {
    return false;
  }

  return true;
}

/**
 * @summary sometimes 3rd party scripts inject field values that we need to capture
 * @param {String} fieldId - id of the field you want to get the value of
 */
export function getDOMFieldValue(fieldId) {
  const fieldValue = (document.getElementById(fieldId) || {}).value;

  if (!fieldValue) {
    LogError(`Failed To Find Form Field ${fieldId}`);
  }

  return fieldValue;
}

/**
 * @summary We need to support options as objects, strings, and numbers
 * @param {Object|String|Number} value - a value of any type
 */
export function findValueInOptions(value, options) {
  let _value = value;

  function isOption(option) {
    // eslint-disable-next-line eqeqeq
    return option.value == value || option.guid == value;
  }

  // need to support value being many types, even grouped options
  if (value && typeof value.value === 'undefined') {
    const selectedGroupOrOption = options.find((groupOrOption) => {
      if (groupOrOption.options) {
        return groupOrOption.options.find((option) => isOption(option));
      }
      return isOption(groupOrOption);
    });

    if (selectedGroupOrOption && selectedGroupOrOption.options) {
      _value = selectedGroupOrOption.options.find((option) => isOption(option));
    } else if (selectedGroupOrOption) {
      _value = selectedGroupOrOption;
    } else {
      _value = { label: value, value, isMissing: true };
    }
  }

  return _value;
}

export function removeNoValuesFromForm(formValues) {
  return Object.keys(formValues).reduce((map, fieldName) => {
    const value = getFieldValue(formValues, fieldName);

    // don't put empty values back in
    if (isInvalidValue(value) || formValues[fieldName].isSelected) {
      return map;
    }

    return { ...map, [fieldName]: formValues[fieldName] };
  }, {});
}

export function scrubPii(formValues, fieldNameMap) {
  let _formValues = formValues;
  try {
    _formValues = Object.keys(formValues).reduce(
      (updatedValues, fieldName) => ({
        ...updatedValues,
        [fieldName]: get(fieldNameMap, `[${fieldName}].isPii`)
          ? ''
          : formValues[fieldName],
      }),
      {}
    );
  } catch (error) {
    LogError('Failed to run scrubPii', {
      errorMessage: error.message,
      fieldNames: Object.keys(formValues).join(', '),
      fieldNameMap: Object.keys(fieldNameMap).join(', '),
    });
  }

  return _formValues;
}

/**
 * @summary This function is used to get a list of question the user should be able to see
 * @param {Array} questions - an array of question to check if they should be shown to the user
 * @param {Object} formValues - all values in the form keyed by the form field name
 * @param {Object} questionsMap - an object that holds all questions in the form, keyed by the id
 */
export function getValidQuestions(
  questions = [],
  formValues = {},
  questionsMap,
  loadedDynamicOptions = {}
) {
  const _questions = [];

  try {
    // for each question derive if we should show
    questions.forEach((question) => {
      // if not dependency.VISIBILITY or DYNAMIC_VISIBILITY show
      if (
        !get(question, 'dependency.VISIBILITY') &&
        !get(question, 'dependency.DYNAMIC_VISIBILITY')
      ) {
        return _questions.push(question);
      }

      // if DYNAMIC_VISIBILITY and has options
      if (
        get(question, 'dependency.DYNAMIC_VISIBILITY') &&
        get(loadedDynamicOptions, `[${question.id}].options`, []).length
      ) {
        return _questions.push(question);
      }

      // NOTE: that we do not check for DYNAMIC_VISIBILITY here because we let backend tell us. loadedDynamicOptions will be empty if they do not want us to show
      const dependantQuestionsIds = get(
        question,
        'dependency.VISIBILITY.questionIds',
        []
      );
      const validAnswers = get(
        question,
        'dependency.VISIBILITY.validAnswers',
        {}
      );

      // its possible this question is dependant on questions that are not in this form
      if (
        !dependantQuestionsIds.length &&
        get(question, 'dependency.VISIBILITY')
      ) {
        _questions.push(question);
      }

      // for every dependant question find out if its answered
      return dependantQuestionsIds.forEach((questionsIds) => {
        const questionName = questionsMap[questionsIds].name;
        const acceptedValues = validAnswers[questionsIds];
        const questionValue = getFieldValue(formValues, questionName);

        if (!acceptedValues && questionValue) {
          _questions.push(question);
        }

        if (acceptedValues) {
          // questionValue can be an array of values
          if (Array.isArray(questionValue)) {
            if (acceptedValues.some((av) => questionValue.includes(av))) {
              _questions.push(question);
            }
          }

          if (acceptedValues.includes(questionValue)) {
            _questions.push(question);
          }

          // in the default visibility case null is in acceptedValues
          if (isInvalidValue(questionValue) && acceptedValues.includes(null)) {
            _questions.push(question);
          }
        }
      });
    });

    return _questions;
  } catch (error) {
    return questions;
  }
}

export function doPreviousStepsHaveVisibleQuestions(state) {
  const { formConfigs, currentStepIndex } = state;
  const nextStepIndex = currentStepIndex - 1;
  const step = formConfigs.steps[nextStepIndex];

  if (!step) {
    return false;
  }

  const prevStepQuestions = getValidQuestions(
    step.questions,
    state.formValues,
    state.allQuestionsInForm,
    state.formStatus.dynamicOptions
  );

  // it could be that this step has no question that match current responses
  const visibleQuestions = getVisibleQuestions(prevStepQuestions, state);

  if (!prevStepQuestions.length || !visibleQuestions.length) {
    return doPreviousStepsHaveVisibleQuestions({
      ...state,
      currentStepIndex: currentStepIndex - 1,
    });
  }

  return true;
}

/**
 * @summary use this to clean form values. It will run the formValues through the dependance graph to clean it up
 * @param {Object} formState.formValues
 * @param {Object} formState.allQuestionsInForm
 * @param {Object} formState.formStatus.dynamicOptions
 * @returns {Object} formValues
 */
export function getValidFormValues(formState) {
  const formValues = { ...formState.formValues };

  try {
    const validQuestionFiledNames = getValidQuestions(
      Object.values(formState.allQuestionsInForm),
      formState.formValues,
      formState.allQuestionsInForm,
      formState.formStatus.dynamicOptions
    ).reduce((map, { name }) => ({ ...map, [name]: true }), {});

    Object.keys(formValues).forEach((fieldName) => {
      if (
        [
          CITY,
          STATE,
          PRIMARY_PHONE,
          PRIMARY_PHONE_TYPE,
          SECONDARY_PHONE,
          SECONDARY_PHONE_TYPE,
        ].includes(fieldName)
      ) {
        return;
      }
      if (!validQuestionFiledNames[fieldName]) {
        delete formValues[fieldName];
      }
    });
  } catch (error) {
    LogError('getCleanInvalidFormValues Failed To Clean User Form', {
      error: error.message,
    });
  }

  return formValues;
}

/**
 * @summary use this when you want to know if the form submit should be disabled
 */
export function isNextStepDisabled(flags) {
  // case for when a returning user tried to resume session before form options come back. Note this is mainly to support the concentration question, we need to make a pit stop before pushing the user to their last answered question
  if (
    flags.formStatus.isProcessing &&
    flags.isFirstStep &&
    flags.hasSessionValues
  ) {
    return true;
  }

  return (
    (flags.isLastStep || flags.isNextStepDynamic) &&
    (flags.formStatus.isSubmitting ||
      flags.formStatus.isProcessing ||
      flags.formStatus.willAutoAdvance)
  );
}

/**
 * @summary client side zip code validation only
 * @param {String} zip - users zip code
 */
export function isValidZip(zip) {
  return zip && zip.length === 5;
}

/**
 * @param {String} phoneNumber - users phone number
 */
export function isValidPhone(phoneNumber) {
  return phoneNumber && phoneNumber.trim().replace(/-/g, '').length === 10;
}

/**
 * @param {String} email - users email
 */
export function isValidEmail(email) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(email);
}

/**
 * @summary use this to get a key based on an objects values
 * @param {String} formValues - current form values
 */
export function getSyncKey(formValues = {}) {
  return Object.keys(formValues)
    .map((name) => getFieldValue(formValues, name))
    .filter(Boolean)
    .join('_');
}

/**
 * This function can be used to get Valid, Visible, unanswered questions.
 * Valid means we are taking dependencies into account.
 * Visible meaning question were not hidden due to user actions.
 * @param {Array} questions - An array of question to check
 * @param {Object} currentFormState - The current state of the form
 * @returns
 */
export function getValidVisibleUnansweredQuestions(
  questions,
  currentFormState
) {
  const thisStepsValidQuestions = getValidQuestions(
    questions,
    currentFormState.formValues,
    currentFormState.allQuestionsInForm,
    currentFormState.formStatus.dynamicOptions
  );
  const thisStepsVisibleQuestions = getVisibleQuestions(
    thisStepsValidQuestions,
    currentFormState
  );
  return getUnansweredQuestions(
    thisStepsVisibleQuestions,
    currentFormState.formValues
  );
}
